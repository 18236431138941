import React from 'react';
import styled from 'styled-components';
import logo from '../../Assets/flayrIcon.png';

const NavbarContainer = styled.div`
    display: flex;
    width: 80%;
    max-width: 1200px;
    height: 80px;
    /* border-bottom: 0.2px solid white; */
`;

const NavItems = styled.div`
    display: flex;
    width: 450px;
    height: 80px;
    margin-left: auto;
    /* border-bottom: 0.2px solid white; */
`;

const NavItem = styled.div`
    padding-top: 26px;
    color: white;
    font-size: 16px;
    margin-left: 16px;
`;

const ConnectBtn = styled.div`
    border: 3px solid white;
    font-size: 20px;

    width: 150px;
    height: 25px;
    margin-top: 15px;
    margin-left: auto;
    border-radius: 10px;
    color: white;
    padding-top: 6px;
    padding-bottom: 6px;

    font-weight: bold;
`;

const NavLogo = styled.div``;

const NavLogoImg = styled.img`
    padding-top: 10px;
    width: 135px;
`;

function Navbar() {
    return (
        <NavbarContainer>
            <NavLogo>
                <NavLogoImg src={logo} alt="logo" />
            </NavLogo>
            <NavItems>
                {/* <NavItem>Home</NavItem>
                <NavItem>About</NavItem>
                <NavItem>Contact</NavItem>
                <NavItem>Sign Up</NavItem> */}
                {/* <ConnectBtn>Connect</ConnectBtn> */}
            </NavItems>
        </NavbarContainer>
    );
}

export default Navbar;

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Portal from './Pages/Portal/Portal';
import ConnectedHome from './Pages/Home/ConnectedHome';

function Routes() {
    const isAuthenticated = false;
    let routes;
    if (isAuthenticated) {
        routes = (
            <Switch>
                <Route path="/portal">
                    <Portal />
                </Route>
                <Redirect to="/portal" />
            </Switch>
        );
    } else if (!isAuthenticated) {
        routes = (
            <Switch>
                <Route path="/home">
                    <ConnectedHome />
                </Route>

                <Redirect to="/home" />
            </Switch>
        );
    }
    return <Router>{routes}</Router>;
}

export default Routes;

import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import Button from '../../Components/Button';
import image1 from '../../Assets/web-design 1.png';
import image2 from '../../Assets/support-ticket 1.png';
import image3 from '../../Assets/viral-marketing 1.png';

const SectionTwoContainer = styled.div`
    height: 640px;
    width: 100%;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
`;

const Row1 = styled.div`
    display: flex;
    flex-direction: column;
    height: 120px;
    margin-left: 100px;
`;

const Title = styled.div`
    display: flex;
    font-size: 36px;
    font-weight: 700;
    color: #1b1c57;
    margin-bottom: 25px;
`;

const ButtonContainer = styled.div`
    display: flex;
    height: 50px;
    align-items: center;
`;

const Row2Container = styled.div`
    display: flex;
    justify-content: center;
`;
const Row2 = styled.div`
    display: flex;
    flex-direction: column;
    width: 900px;
    height: 350px;
    margin-top: 50px;
`;

const ImagesContainer = styled.div`
    display: flex;
    align-items: center;
    height: 200px;
`;

const StepsContainer = styled.div`
    display: flex;
    height: 150px;
`;

const ImageContainer = styled.div`
    height: 100%;
    width: 100%;
`;
const Image = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`;

const StepsBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 20px;
`;

const StepsTitle = styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #0e1735;
    padding-bottom: 10px;
`;

const StepsDescription = styled.div`
    font-size: 16px;
    line-height: 32px;
`;

const SectionTwo = () => {
    return (
        <SectionTwoContainer>
            <Row1>
                <Title>How It Works</Title>
                <ButtonContainer>
                    <Button
                        style={{ border: 'unset', marginRight: '30px' }}
                        label="Ticket Creators"
                        width="181px"
                        height="40px"
                        backgroundColor="#82BBFF"
                        textColor="white"
                        hoverBackgroundColor="#5B71FD"
                        hoverTextColor="white"
                    />
                    <Button
                        label="Ticket Buyers"
                        width="167px"
                        height="40px"
                        backgroundColor="white"
                        textColor="black"
                        hoverBackgroundColor="#5B71FD"
                        hoverTextColor="white"
                    />
                </ButtonContainer>
            </Row1>
            <Row2Container>
                <Row2>
                    <ImagesContainer>
                        <ImageContainer>
                            <Image alt="design-ticket" src={image1} />
                        </ImageContainer>
                        <ImageContainer>
                            <Image alt="add-features" src={image2} />
                        </ImageContainer>
                        <ImageContainer>
                            <Image alt="share-ticket" src={image3} />
                        </ImageContainer>
                    </ImagesContainer>
                    <StepsContainer>
                        <StepsBox>
                            <StepsTitle>1. Design your ticket</StepsTitle>
                            <StepsDescription>
                                Using our AI-powered, easy-to-use design tool.
                            </StepsDescription>
                        </StepsBox>
                        <StepsBox>
                            <StepsTitle>2. Add custom features</StepsTitle>
                            <StepsDescription>
                                Set your ticket quantity, enable or disable reselling, and more.
                            </StepsDescription>
                        </StepsBox>
                        <StepsBox>
                            <StepsTitle>3. Share your ticket</StepsTitle>
                            <StepsDescription>
                                Fans can purchase tickets with up to 90% reduced fees over other
                                platforms.
                            </StepsDescription>
                        </StepsBox>
                    </StepsContainer>
                </Row2>
            </Row2Container>
        </SectionTwoContainer>
    );
};

export default SectionTwo;

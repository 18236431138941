import React from 'react';
import styled from 'styled-components';
import iphoneMockup from '../../Assets/iphone-mockup.png';
import twitterIcon from '../../Assets/twitter-outline-white.png';
import useWindowSize from '../../Hooks/useWindowSize';

const SectionOneContainer = styled.div`
    margin-top: 60px;
    height: 800px;
    width: 80%;
    max-width: 1200px;
    display: flex;

    @media only screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: center;
    }

    @media only screen and (max-width: 620px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Header1Section = styled.div`
    flex-direction: column;
    height: 180px;
    display: flex;

    @media only screen and (max-width: 1300px) {
        align-items: center;
        order: 1;
    }

    @media only screen and (max-width: 620px) {
        align-items: center;
        order: 1;
        width: 330px;
    }
`;

const SubheaderSection = styled.div`
    flex-direction: column;
    height: 180px;
    display: flex;

    @media only screen and (max-width: 1300px) {
        align-items: center;
        order: 2;
        margin-top: 30px;
    }

    @media only screen and (max-width: 620px) {
        margin-top: 10px;
        align-items: center;
        order: 2;
        width: 330px;
    }
`;

const Header1SectionImage = styled.div`
    width: 100%;
    max-width: 700px;
    display: flex;

    @media only screen and (max-width: 1300px) {
        margin-top: 0px;
        order: 3;
    }

    @media only screen and (max-width: 620px) {
        order: 3;
    }
`;

const Header1SectionImg = styled.img`
    width: 600px;
    height: auto;
    object-fit: cover;
    overflow: visible;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 1300px) {
        order: 3;
        width: auto;
        height: auto;
        max-width: 100%;
    }

    @media only screen and (max-width: 620px) {
        order: 3;
        width: 600px;
    }
`;
const Header = styled.div`
    color: white;
    font-size: 50px;
    width: 560px;
    text-align: left;
    height: 230px;
    font-weight: bold;
    margin-bottom: 10px;

    @media only screen and (max-width: 1300px) {
        text-align: center;
        font-size: 58px;
        width: 400px;
    }
    @media only screen and (max-width: 620px) {
        text-align: center;
        font-size: 45px;
        width: 300px;
    }
`;
const Subheader = styled.div`
    color: white;
    font-size: 20px;
    width: 600px;
    text-align: left;
    margin-right: auto;
    height: 80px;
    font-weight: bold;

    @media only screen and (max-width: 1300px) {
        text-align: center;
        order: 2;
        font-size: 20px;
    }

    @media only screen and (max-width: 620px) {
        align-items: center;
        order: 2;
        font-size: 15px;
        width: 100%;
    }
`;

const Description1 = styled.div`
    color: white;
    font-size: 20px;
    width: 550px;
    margin-top: 20px;
    text-align: left;
    order: 3;

    @media only screen and (max-width: 1300px) {
        text-align: center;
        width: 600px;
        font-size: 30px;
    }

    @media only screen and (max-width: 620px) {
        text-align: left;
        width: 350px;
        font-size: 18px;
    }
`;

const Description2 = styled.div`
    color: white;
    font-size: 20px;
    width: 550px;
    margin-top: 20px;
    text-align: left;
    order: 3;

    @media only screen and (max-width: 1300px) {
        text-align: center;
        width: 600px;
        font-size: 30px;
    }

    @media only screen and (max-width: 620px) {
        text-align: right;
        width: 350px;
        font-size: 18px;
    }
`;
const TwitterTxt = styled.div`
    margin-top: 10px;
    font-size: 16px;
`;
const TwitterTxtLink = styled.a`
    color: white;
    text-decoration: none;
    :hover {
        color: #800080;
    }
`;

const TwitterIcon = styled.img`
    height: 15px;
    margin-right: 4px;
`;

const JoinUs = styled.div`
    border: 1px solid red;
`;

const SectionOneResponse = () => {
    const size = useWindowSize();

    const isMobile = size.width < 1300;

    return (
        <SectionOneContainer>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Header1Section>
                    <Header>
                        Thanks! You’re all <span style={{ color: '#800080' }}>set</span>
                    </Header>
                    {isMobile ? (
                        <Header1SectionImage>
                            <Header1SectionImg src={iphoneMockup} alt="iphone" />
                        </Header1SectionImage>
                    ) : null}
                    <SubheaderSection>
                        <Subheader>
                            We’ll notify you when <span style={{ color: '#800080' }}> Flayr</span>{' '}
                            launches along with details about your welcome offer.{' '}
                        </Subheader>
                    </SubheaderSection>
                    <Description1>
                        <span style={{ color: '#800080', fontWeight: 'bold' }}> Fans</span> - your
                        journey to saving big on concert tickets starts here. Say goodbye to
                        overpriced fees and hello to unforgettable experiences.
                        <TwitterTxt>
                            <TwitterTxtLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/intent/tweet?text=Join%20me%20in%20making%20live%20music%20accessible%20to%20all%3A&url=https%3A%2F%2Fflayrlabs.com%2F&via=FlayrLabs"
                            >
                                <TwitterIcon src={twitterIcon} alt="twitter" />@ mention your
                                favorite artist to get them on board!
                            </TwitterTxtLink>
                        </TwitterTxt>
                    </Description1>
                    <Description2>
                        <span style={{ color: '#800080', fontWeight: 'bold' }}> Artists</span> - get
                        ready for a game-changing experience in concert ticket design and
                        distribution. Your fans will soon thank you for giving them the gift of
                        memorable digital tickets and fair, honest pricing.
                        <TwitterTxt>
                            <TwitterTxtLink
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://twitter.com/intent/tweet?text=Join%20me%20in%20making%20live%20music%20accessible%20to%20all%3A&url=https%3A%2F%2Fflayrlabs.com%2F&via=FlayrLabs"
                            >
                                Share on Twitter to let your fans know!
                                <TwitterIcon
                                    style={{ marginLeft: '4px' }}
                                    src={twitterIcon}
                                    alt="twitter"
                                />
                            </TwitterTxtLink>
                        </TwitterTxt>
                    </Description2>
                </Header1Section>
            </div>
            {isMobile === false ? (
                <Header1SectionImage>
                    <Header1SectionImg src={iphoneMockup} alt="iphone" />
                </Header1SectionImage>
            ) : null}
        </SectionOneContainer>
    );
};

export default SectionOneResponse;

import axios from 'axios';

const usePost = (url) => {
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const post = (body) => {
        const options = {
            url: `${baseURL}${url}`,
            method: 'POST',
            data: body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
            },
        };

        return axios(options);
    };

    return {
        post,
    };
};

export default usePost;

import React, { useState } from 'react';
import usePost from '../../Hooks/usePost';
import Navbar from './Navbar';
import styled from 'styled-components';
import SectionOneForm from './SectionOneForm';
import SectionOneResponse from './SectionOneResponse';
import SectionTwo from './SectionTwo';
import backgroundImage from '../../Assets/flayrBackgroundImage.png';

const PageContainer = styled.div`
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    background-position: center;
`;

const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

function ConnectedHome() {
    const [sectionOneCompleted, setSectionOneCompleted] = useState(false);
    const [postData, setPostData] = useState({
        success: false,
        error: false,
        isLoading: false,
        message: '',
    });

    const [emailVal, setEmailVal] = useState('');
    const { post } = usePost('/welcome');

    const emailIsValid = emailVal.length > 0;

    const saveWelcomeForm = async () => {
        try {
            setPostData({ ...postData, message: '', isLoading: true });
            if (emailIsValid) {
                const postResp = await post({ email: emailVal });
                const saved = postResp.data;
                if (saved) {
                    setPostData({ ...postData, isLoading: false, success: true });
                    setSectionOneCompleted(true);
                }
            } else {
                setPostData({
                    ...postData,
                    isLoading: false,
                    success: false,
                    error: true,
                    message: 'Please enter a valid email address.',
                });
            }
        } catch (err) {
            setPostData({
                ...postData,
                isLoading: false,
                success: false,
                error: true,
                message: 'Something went wrong, please try again later.',
            });
        }
    };

    return (
        <PageContainer imageUrl={backgroundImage}>
            <HomeContainer>
                <SectionOneForm
                    setEmailVal={setEmailVal}
                    emailVal={emailVal}
                    saveWelcomeForm={saveWelcomeForm}
                    postData={postData}
                />

                <SectionTwo />
            </HomeContainer>
        </PageContainer>
    );
}

export default ConnectedHome;

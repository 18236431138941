import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import iphoneMockup from '../../Assets/iphone-mockup.png';
import useWindowSize from '../../Hooks/useWindowSize';
import image1 from '../../Assets/FlayrImage1.jpeg';
import logo from '../../Assets/flayrIcon.png';
import Button from '../../Components/Button';

const SectionOneContainer = styled.div`
    height: 640px;
    width: 100%;
    display: flex;
`;

const ColOne = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const ColOneContainer = styled.div`
    margin-left: 100px;
    display: flex;
    flex-direction: column;
`;

const ColTwo = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
`;

const ColTwoImageContainer = styled.div`
    height: 100%;
    border-bottom-left-radius: 80px;
    position: relative;
`;
const ColTwoImage = styled.img`
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-bottom-left-radius: 80px;
`;

const NavLogo = styled.div`
    display: flex;
`;

const NavLogoImg = styled.img`
    padding-top: 10px;
    width: 135px;
`;

const HeaderContainer = styled.div`
    display: flex;
    font-size: 55px;
    width: 531px;
    color: #082f69;
    font-weight: 700;
    margin-top: 80px;
    line-height: 115.68%;

    text-align: left;
`;

const SubsectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 16px;
    width: 531px;
    color: ##082f69;
    font-weight: 700;
    margin-top: 80px;
    line-height: 25px;

    text-align: left;
`;

const Overlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #bdd8e0bf;
    height: 92px;
    width: 100%;
`;

const MenuItems = styled.div`
    display: flex;
    justify-content: right;
    width: 100%;
    align-items: center;
`;

const HelpBoxContainer = styled.div`
    position: absolute;
    justify-content: space-evenly;
    display: flex;
    bottom: 40px;
    height: 180px;
    align-items: center;
    width: 100%;
`;

const HelpBox = styled.div`
    background-color: white;
    width: 257px;
    height: 121px;
    border-radius: 32px;
    padding: 24px;
`;

const SectionOneForm = ({ saveWelcomeForm, setEmailVal, emailVal, postData }) => {
    const handleWelcomeSubmit = () => {
        saveWelcomeForm();
    };

    const size = useWindowSize();

    const isUltraWide = size.width < 2568 && size.width > 1440;
    const isStandard = size.width < 1440 && size.width > 390;
    const isMobile = size.width < 390;

    console.log(isStandard, 'standard');
    console.log(isUltraWide, 'wide');
    console.log(isMobile, 'mobile');

    const { isLoading } = { ...postData };

    return (
        <SectionOneContainer>
            <ColOne>
                <ColOneContainer>
                    <NavLogo>
                        <NavLogoImg src={logo} alt="logo" />
                    </NavLogo>
                    <HeaderContainer>Ticketing Made Accessible For All.</HeaderContainer>
                    <SubsectionContainer>
                        <div>
                            Flayr is a next-generation, community oriented solution for event
                            ticketing.
                        </div>
                        <div style={{ fontWeight: 'normal', marginTop: '30px' }}>
                            Our platform enables creators to design and distribute tickets using
                            blockchain technology, and allows ticket buyers to bypass impractical
                            and expensive fees.
                        </div>
                    </SubsectionContainer>

                    <Button
                        style={{ marginTop: '30px', border: 'unset' }}
                        label="Get Started"
                        width="130px"
                        height="46px"
                        backgroundColor="#5B71FD"
                        textColor="white"
                        hoverBackgroundColor="#82BBFF"
                        hoverTextColor="white"
                    />
                </ColOneContainer>
            </ColOne>
            <ColTwo>
                <ColTwoImageContainer>
                    <Overlay>
                        <MenuItems>
                            <Button
                                style={{ border: 'unset', marginRight: '30px', marginLeft: '30px' }}
                                label="About"
                                width="72px"
                                height="30px"
                                backgroundColor="white"
                                textColor="black"
                                hoverBackgroundColor="#5B71FD"
                                hoverTextColor="white"
                            />
                            <Button
                                style={{ border: 'unset', marginRight: '30px' }}
                                label="Contact"
                                width="84px"
                                height="30px"
                                backgroundColor="white"
                                textColor="black"
                                hoverBackgroundColor="#5B71FD"
                                hoverTextColor="white"
                            />
                            <Button
                                style={{ border: 'unset', marginRight: '100px' }}
                                label="Features"
                                width="110px"
                                height="30px"
                                backgroundColor="white"
                                textColor="black"
                                hoverBackgroundColor="#5B71FD"
                                hoverTextColor="white"
                            />
                            <Button
                                style={{ border: 'unset', marginRight: '30px' }}
                                label="Sign In"
                                width="76px"
                                height="30px"
                                backgroundColor="white"
                                textColor="black"
                                hoverBackgroundColor="#5B71FD"
                                hoverTextColor="white"
                            />
                            <Button
                                style={{ border: 'unset', marginRight: '30px' }}
                                label="Sign Up"
                                width="105px"
                                height="37px"
                                backgroundColor="#5B71FD"
                                textColor="white"
                                hoverBackgroundColor="#82BBFF"
                                hoverTextColor="white"
                            />
                        </MenuItems>
                    </Overlay>
                    <HelpBoxContainer>
                        <HelpBox style={{ width: '197px' }}>
                            <div style={{ color: '#1B1C57', fontSize: '16px', fontWeight: '700' }}>
                                Ridiculously Low Fees
                            </div>
                            <br />
                            <div style={{ lineHeight: '24px' }}>
                                Up to 90% cheaper to buy and sell than other event ticketing
                                platforms.
                            </div>
                        </HelpBox>
                        <HelpBox style={{ width: '231px' }}>
                            <div style={{ color: '#1B1C57', fontSize: '16px', fontWeight: '700' }}>
                                Interactive Design Tool
                            </div>
                            <br />
                            <div style={{ lineHeight: '24px' }}>
                                Flayr’s design tool allows artists to create one-of-a-kind tickets
                                your fans are sure to love.
                            </div>
                        </HelpBox>
                        {isUltraWide ? (
                            <HelpBox style={{ width: '461px' }}>
                                <div
                                    style={{
                                        color: '#1B1C57',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                    }}
                                >
                                    Solutions for Event Hosts and Purchasers
                                </div>
                                <br />
                                <div style={{ lineHeight: '24px' }}>
                                    Our platform benefits not only those selling tickets, but ticket
                                    purchasers. Buying through Flayr means low fees and a
                                    frustration-free experience.
                                </div>
                            </HelpBox>
                        ) : null}
                    </HelpBoxContainer>

                    <ColTwoImage alt="concert-image" src={image1} />
                </ColTwoImageContainer>
            </ColTwo>
        </SectionOneContainer>
    );
};

SectionOneForm.propTypes = {
    saveWelcomeForm: propTypes.func,
    setEmailVal: propTypes.func,
    emailVal: propTypes.string,
    postData: propTypes.shape({
        isLoading: propTypes.bool,
        error: propTypes.bool,
        message: propTypes.string,
    }),
};

SectionOneForm.defaultProps = {
    setEmailVal: () => {},
    saveWelcomeForm: () => {},
    emailVal: '',
    postData: {},
};

export default SectionOneForm;

import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

const StyledButton = styled.button`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
    border-radius: 32px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    :hover {
        background-color: ${(props) => props.hoverBackgroundColor};
        color: ${(props) => props.hoverTextColor};
    }
`;

const Button = ({
    width,
    height,
    backgroundColor,
    label,
    textColor,
    hoverBackgroundColor,
    hoverTextColor,
    style,
}) => {
    return (
        <StyledButton
            hoverBackgroundColor={hoverBackgroundColor}
            hoverTextColor={hoverTextColor}
            style={style}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            color={textColor}
        >
            {label}
        </StyledButton>
    );
};

Button.propTypes = {
    width: propTypes.string,
    height: propTypes.string,
    backgroundColor: propTypes.string,
    label: propTypes.string,
    textColor: propTypes.string,
    style: propTypes.shape({}),
    hoverBackgroundColor: propTypes.string,
    hoverTextColor: propTypes.string,
};

Button.defaultProps = {};

export default Button;
